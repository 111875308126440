.header-filter {
    padding: 10px;
    background-color: #ff002b;
    /* border: 1px solid #000; */
    max-width: 100%;
    box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.header-filter label {
    color: #fff;
    font-weight: 500;
}